import type {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {CustomDataValues} from '../../types/CustomDataValues';
import {logger} from '../../utils/Logger';
import {
  hasPlayerAlreadyBeenAugmented,
  isDefined,
  markPlayerInstanceAsAugmented,
} from '../../utils/playerAugmentationUtils';
import {VERSION} from '../../Version';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {ShakaInternalAdapter} from './ShakaInternalAdapter';

export class ShakaAdapter implements AdapterAPI {
  private readonly internalAdapter: InternalAdapterAPI | undefined;
  private readonly analytics: Analytics | undefined;

  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    if (hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    markPlayerInstanceAsAugmented(player);

    this.internalAdapter = new ShakaInternalAdapter(player, opts);
    this.analytics = Analytics.create(config, this.internalAdapter);
    this.wrapPlayerLoad(player);
  }

  // The Shaka player's `load()` method does not trigger error events for exceptions thrown while loading the manifest.
  // Therefore, we wrap this method and catch exceptions and forward them to analytics.
  // TODO [AN-4149]: this needs to be reevaluated, since the cypress test with a none existing manifest is not working
  private wrapPlayerLoad(player: any) {
    const originalLoad = player.load;

    player.load = (...args) =>
      new Promise((resolve, reject) => {
        originalLoad
          .apply(player, args)
          .then((result) => resolve(result))
          .catch((error) => {
            const eventObject = {
              currentTime: 0,
              // See https://github.com/shaka-project/shaka-player/blob/main/lib/util/error.js
              code: error?.code ?? undefined,
              message: JSON.stringify({category: error?.category, severity: error?.severity}),
              data: {additionalData: JSON.stringify(error?.data)},
            };

            (this.internalAdapter as ShakaInternalAdapter).eventCallback(Event.ERROR, eventObject);

            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            reject(error);
          });
      });
  }

  static readonly version: string = VERSION;

  get version(): string {
    return VERSION;
  }

  getCurrentImpressionId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getCurrentImpressionId();
  }

  getUserId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getUserId();
  }

  setCustomData(values: CustomDataValues) {
    if (!isDefined(this.internalAdapter)) return;

    this.internalAdapter.setCustomData(values);
  }

  setCustomDataOnce(values: CustomDataValues) {
    if (!isDefined(this.analytics)) return;

    this.analytics.setCustomDataOnce(values);
  }

  sourceChange(config: AnalyticsConfig) {
    if (!isDefined(this.analytics)) return;

    this.analytics.sourceChange(config);
  }
}
